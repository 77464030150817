import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import useWidth from 'src/hooks/window/useWidth'

import { grayscale, white } from 'src/styles/colors'
import { WIDTH_MD } from 'src/utils/breakpoints'

import textPT from '../../assets/data/translate/voce-no-controle-do-seu-dinheiro/text-pt.json'
import backgrounds from '../../assets/img/backgrounds'
import * as S from './style'

const VoceNoControleDoSeuDinheiro = React.forwardRef((props: { setIsOpen: Function }, ref: React.Ref<HTMLDivElement>) => {
  const width = useWidth()
  const { title, CTA }: ISlideScrollSections = textPT
  const { setIsOpen }: { setIsOpen: Function } = props
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.ModelsSectionBackground
      key='TESTE 6'
      className='d-flex align-items-end align-items-md-center'
      mobileBackgroundImage={backgrounds.backgroundHomeSection7.mobile}
      backgroundImage={backgrounds.backgroundHomeSection7.desktop}
      ref={ref}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-7 col-lg-12'>
            <div className=''>
              <S.TitleExtraLarge as='h2' className='fw-400 f-sora text-white' dangerouslySetInnerHTML={{ __html: title }} />
              <div>
                <S.CTA
                  as='button'
                  className='text-white'
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_06',
                      section_name: 'Você no controle do seu dinheiro',
                      element_action: 'click button',
                      element_name: CTA.text,
                    })
                  }}
                >
                  {CTA.text}
                  <ArrowRight height={24} width={24} color={width < WIDTH_MD ? white : grayscale[500]} className='ml-2' />
                </S.CTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
})

export default VoceNoControleDoSeuDinheiro
