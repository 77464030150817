import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'

import textPT from '../../assets/data/translate/conta-pj-pra-superar-os-desafios/text-pt.json'
import backgrounds from '../../assets/img/backgrounds'

import * as S from './style'

const ContaPJPraSuperarOsDesafios = () => {
  const { title, CTA }: ISlideScrollSections = textPT
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.ModelsSectionBackground
      key='TESTE 3'
      className='d-flex align-items-end align-items-md-center'
      mobileBackgroundImage={backgrounds.backgroundHomeSection4.mobile}
      backgroundImage={backgrounds.backgroundHomeSection4.desktop}
      modelName='TESTE 3'
    >
      <div className='container-fluid'>
        <div className='row justify-content-md-end'>
          <div className='col-12 col-md-6 offset-md-6'>
            <S.TitleLarge as='h2' className='fw-400 f-sora text-white' dangerouslySetInnerHTML={{ __html: title }} />
            <div>
              <S.CTA
                to={CTA.link}
                className='text-white text-none'
                aria-label={CTA.text}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Conta PJ pra superar os desafios do seu negócio.',
                    element_action: 'click button',
                    element_name: CTA.text,
                    redirect_url: CTA.link,
                  })
                }}
              >
                {CTA.text}
                <ArrowRight height={24} width={24} color='#FFFF' className='ml-2' />
              </S.CTA>
            </div>
          </div>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
}

export default ContaPJPraSuperarOsDesafios
