import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'src/components/Modal'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import { GlobalContext, IGlobalContext } from 'src/context/GlobalContext'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_LG } from 'src/styles/breakpoints'
import * as S from './style'

import ASuaVidaFinanceiraMaisSegura from './sections/a-sua-vida-financeira/_index'
import BuscaQueOInterTem from './sections/busca-que-o-inter-tem/_index'
import ContaPJPraSuperarOsDesafios from './sections/conta-pj-pra-superar-os-desafios/_index'
import FacilRapidoGratuito from './sections/facil-rapido-e-gratuito/_index'
import HomeFooter from './sections/footer/_index'
import SuaVidaGlobalEInter from './sections/sua-vida-globa-e-inter/_index'
import UmaVidaFinanceiraInteligente from './sections/uma-vida-financeira-inteligente/_index'
import VoceNoControleDoSeuDinheiro from './sections/voce-no-controle-do-seu-dinheiro/_index'

const SlideScrollSections = () => {
  const listRef = React.useRef<HTMLDivElement>(null)
  const { setHeaderColorContrast, setScrollTopHomeValue, setScrollHomeMobileValue }: IGlobalContext = useContext(GlobalContext)
  const windowWidth = useWidth()

  const umaVidaFinanceiraInteligenteRef = React.useRef<HTMLDivElement>(null)
  const facilRapidoGratuitoRef = React.useRef<HTMLDivElement>(null)
  const buscaQueOInterTemRef = React.useRef<HTMLDivElement>(null)
  const voceNoControleDoSeuDinheiroRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    setHeaderColorContrast(true)
  }, [])

  React.useLayoutEffect(() => {
    const handleScroll = () => {
      const umaVidaFinanceiraInteligenteTopDistance = umaVidaFinanceiraInteligenteRef.current?.getBoundingClientRect().top || 0
      const facilRapidoGratuitoRefTopDistance = facilRapidoGratuitoRef.current?.getBoundingClientRect()?.top || 0
      const buscaQueOInterTemTopDistance = buscaQueOInterTemRef.current?.getBoundingClientRect()?.top || 0
      const voceNoControleDoSeuDinheiroTopDistance = voceNoControleDoSeuDinheiroRef.current?.getBoundingClientRect()?.top || 0
      const scrollTopHomeValue = listRef.current?.scrollTop

      if (scrollTopHomeValue) {
        setScrollTopHomeValue(scrollTopHomeValue)
      }

      if ((
        umaVidaFinanceiraInteligenteTopDistance &&
        facilRapidoGratuitoRefTopDistance &&
        buscaQueOInterTemTopDistance &&
        voceNoControleDoSeuDinheiroTopDistance
      ) === Number(0)) {
        setHeaderColorContrast(true)
      } else {
        setHeaderColorContrast(false)
      }
    }

    const handleScrollMobile = () => {
      if (listRef) {
        setScrollHomeMobileValue(listRef.current?.scrollTop || 0)
      }
    }

    if (windowWidth >= WIDTH_LG) {
      if (listRef.current) {
        listRef.current.addEventListener('scroll', handleScroll)

        return () => {
          listRef.current && listRef.current.removeEventListener('scroll', handleScroll)
        }
      }
    }

    if (windowWidth < WIDTH_LG) {
      if (listRef.current) {
        listRef.current.addEventListener('scroll', handleScrollMobile)
        return () => {
          listRef.current && listRef.current.removeEventListener('scroll', handleScrollMobile)
        }
      }
    }
  }, [ listRef, windowWidth ])

  const [ isOpen, setIsOpen ] = useState(false)

  const domReady = useDomReady()

  const modal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setIsOpen(false)}
        deepLink='https://intergo.app/41553999'
        dataLayer={{
          section_name: 'Abra agora sua Conta Digital',
          section: 'dobra_01',
          element_action: 'submit',
          element_name: 'continuar',
        }}
      />
    </Modal>
  )

  return (
    <S.Container ref={listRef} id='my-scroll-container'>
      {modal}
      <UmaVidaFinanceiraInteligente setIsOpen={setIsOpen} ref={umaVidaFinanceiraInteligenteRef} />
      <ASuaVidaFinanceiraMaisSegura setIsOpen={setIsOpen} />
      <ContaPJPraSuperarOsDesafios />
      <FacilRapidoGratuito ref={facilRapidoGratuitoRef} />
      <BuscaQueOInterTem ref={buscaQueOInterTemRef} />
      <VoceNoControleDoSeuDinheiro setIsOpen={setIsOpen} ref={voceNoControleDoSeuDinheiroRef} />
      <SuaVidaGlobalEInter />
      <HomeFooter />
      <S.Spacer />
    </S.Container>
  )
}

export default SlideScrollSections
