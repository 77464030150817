import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import BackgroundVideo from './BackgroundVideo'

import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

import textPT from '../../assets/data/translate/facil-rapido-e-gratuito/text-pt.json'
import * as S from './style'

const FacilRapidoGratuito = React.forwardRef((props: {}, ref: React.Ref<HTMLDivElement>) => {
  const { title, description, CTA }: ISlideScrollSections = textPT
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.ModelsSectionBackground
      key='TESTE 5'
      className='d-flex align-items-end align-items-md-center'
      ref={ref}
    >
      <>
        <BackgroundVideo
          urlMp4='https://central-imagens.bancointer.com.br/gif/cartao-pj-gif/arquivo.webm'
          urlWeb='https://central-imagens.bancointer.com.br/gif/cartao-pj-gif/arquivo.webm'
          videoSrc={ {
            mobile: 'https://central-imagens.bancointer.com.br/gif/gif-cartao-pj-mobike/arquivo.webm',
            desktop: 'https://central-imagens.bancointer.com.br/gif/cartao-pj-gif/arquivo.webm',
          }}
        />
        <S.ContainerVideo className='container'>
          <div className='row padding-area'>
            <div className='col-12'>
              <S.TitleExtraLarge
                as='h2'
                className={`${windowWidth < WIDTH_MD ? 'text-white' : 'text-grayscale--500'} fw-400`}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p
                className={`fs-20 lh-25 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 f-sora fw-400 ${windowWidth < WIDTH_MD ? 'text-white' : 'text-grayscale--500'} my-3`}
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <div>
                <S.CTA
                  to={CTA.link}
                  className={`${windowWidth < WIDTH_MD ? 'text-white' : 'text-grayscale--500'}`}
                  aria-label={CTA.text}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_04',
                      section_name: 'Fácil, rápido e gratuito.',
                      element_action: 'click button',
                      element_name: CTA.text,
                      redirect_url: CTA.link,
                    })
                  }}
                >
                  {CTA.text}
                  <ArrowRight height={24} width={24} color={windowWidth < WIDTH_MD ? 'white' : grayscale[500]} className='ml-2' />
                </S.CTA>
              </div>
            </div>
          </div>
        </S.ContainerVideo>
      </>
    </S.ModelsSectionBackground>
  )
})

export default FacilRapidoGratuito
